jQuery(document).ready(function($) {
    var checkboxesBrands = $('.filter-hardware');
    var checkboxesTipologia = $('.filter-tipologia-hardware');
    var checkboxesPrice = $('#price-filter-form .price-checkbox input[type="checkbox"]');
    var filterForm = $('#filter-form');
    var filterFormPrice = $('#price-filter-form');

    checkboxesBrands.change(function() {
        filterForm.submit();
    });

    checkboxesTipologia.change(function() {
        filterForm.submit();
    });

    checkboxesPrice.change(function() {
        filterFormPrice.submit();
    });

    var resetButton = $('#reset-filters');
    
    function updateResetButtonVisibility() {
        var hasFilters = window.location.search.indexOf('filter_hardware') > -1 || window.location.search.indexOf('filter_tipologia_hardware') > -1 || window.location.search.indexOf('price_filter') > -1;
        resetButton.toggle(hasFilters);
    }

    resetButton.click(function() {
        var baseUrl = window.location.href.split('?')[0];
        window.location.href = baseUrl;
    });

    updateResetButtonVisibility();
});
