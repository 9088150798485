export function initializeNavbarTogglers($) {
  function resetTogglers() {
    $(".container .navbar-toggler .fa-bars").show();
    $(".container .navbar-toggler .fa-x").hide();
  }

  resetTogglers();

  $(".navbar-toggler").on("click", function () {
    $(".container .navbar-toggler .fa-bars, .container .navbar-toggler .fa-x").toggle();
  });
}

export function initializeDropdownToggle($) {
  $("body").off("click.bs.dropdown.data-api", ".dropdown-toggle");

  $("body").on('click', ".active-megamenu > a", function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.menu-item--expanded .dropdown-menu').removeClass('show');
    var $dropdownMenu = $(this).siblings('.dropdown-menu--megamenu');
    $('.dropdown-menu--megamenu').not($dropdownMenu).hide(); 

    if ($(window).width() >= 992) {
      $dropdownMenu.toggleClass("grid-megamenu");
    } else {
      $dropdownMenu.toggle();
    }
  });

  if ($(window).width() <= 992) {
    $('.dropdown-menu--megamenu').hide(); 
  }

  //hide when click outsite
  $(document).on("click", function (e) {
    var $target = $(e.target);
    if (!$target.closest('.active-megamenu').length) {
        $('.dropdown-menu--megamenu').removeClass("grid-megamenu");
    }
});
}

export function initializeSidebarToggle($) {
  function initToggleSidebar() {
    var sidebar = $(".sidebar-negozio");
    if ($(window).width() <= 992) {
      sidebar.hide();
    }

    $("#toggleSidebar").off("click").on("click", function () {
      sidebar.slideToggle();
    });
  }

  initToggleSidebar();

  $(document).ajaxSuccess(function(event, xhr, settings) {
    if (settings.url.includes("yith_wcan_render_filter")) {
      initToggleSidebar();
    }
  });
}

export function checkWindowSizeOnLoad($) {
  function checkWindowSize() {
    var maxLG = 992; 
    if ($(window).width() <= maxLG) {
      initializeSidebarToggle($);
    } else {
      $("#toggleSidebar").hide();
    }
  }

  checkWindowSize();

  if ($(window).width() >= 992) {
    $('#navbarPrimaryNavigation').removeClass('show');
    $(".container .navbar-toggler .fa-bars").show();
    $(".container .navbar-toggler .fa-x").hide();
  }
}

export function handleResizeEvents($) {
  checkWindowSizeOnLoad($);
  if ($(window).width() >= 992) {
    $('#navbarPrimaryNavigation').removeClass('show');
    $(".container .navbar-toggler .fa-bars").show();
    $(".container .navbar-toggler .fa-x").hide();
  }
}
